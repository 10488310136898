import { subscribeEmail } from "activecampaign/subscribeEmail";
import {
  NewsletterAnimContainer,
  NewsletterBoxButton,
  NewsletterBoxContainer,
  NewsletterBoxDescription,
  NewsletterBoxError,
  NewsletterBoxForm,
  NewsletterBoxInput,
  NewsletterBoxInputContainer,
  NewsletterBoxTitle,
} from "components/NewsletterBox/NewsletterBox.components";
import { AnimatePresence } from "framer-motion";
import dynamic from "next/dynamic";
import React, { useCallback, useRef, useState } from "react";
import { Config } from "utils/Config";
import { trackEvent } from "utils/googleAnalytics";
import validator from "validator";

const AnimatedParticlesBackground = dynamic(
  () =>
    import(
      "components/AnimatedParticlesBackground/AnimatedParticlesBackground"
    ),
  { ssr: false },
);

const NewsletterBox = () => {
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback(
    async (email: string) => {
      if (!validator.isEmail(email)) {
        setError("Please enter valid email address.");
      } else {
        setError("");
        setSubmitted(true);
        try {
          await subscribeEmail(email);
          const { category, action } = Config.googleAnalytics;
          trackEvent(category, action);
        } catch (error) {
          // No error - always show subscribed message.
        }
      }
    },
    [setError, setSubmitted],
  );

  return (
    <NewsletterBoxContainer>
      <AnimatedParticlesBackground>
        <NewsletterAnimContainer>
          <NewsletterBoxTitle>Stay in touch</NewsletterBoxTitle>
          <NewsletterBoxDescription>
            {submitted
              ? "Get the latest updates about Medical Logistics and healthcare industry delivered to your inbox twice a month."
              : "Get the latest updates about Medical Logistics and healthcare industry delivered to your inbox twice a month"}
          </NewsletterBoxDescription>
          <AnimatePresence>
            {!submitted && (
              <NewsletterBoxInputContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, height: 0, margin: 0 }}
              >
                <NewsletterBoxForm
                  onSubmit={(event) => {
                    const text = inputRef.current?.value || "";
                    handleSubmit(text.trim());
                    event.preventDefault();
                  }}
                >
                  <NewsletterBoxInput
                    ref={inputRef}
                    type="email"
                    placeholder="Enter your email"
                    onChange={() => setError("")}
                  />
                  <NewsletterBoxButton>Sign me up</NewsletterBoxButton>
                </NewsletterBoxForm>
                <NewsletterBoxError>
                  {error || String.fromCharCode(160)}
                </NewsletterBoxError>
              </NewsletterBoxInputContainer>
            )}
          </AnimatePresence>
        </NewsletterAnimContainer>
      </AnimatedParticlesBackground>
    </NewsletterBoxContainer>
  );
};

export default NewsletterBox;
