import TagLink from "components/Tags/TagLink";
import { TagsContainer } from "components/Tags/Tags.components";
import React, { FunctionComponent } from "react";
import { getTag } from "utils/tagUtils";

type TagsProps = {
  tags: string[];
  isBig?: boolean;
  currentTag?: string;
  dummy?: boolean;
  aligment?: "center" | "right" | "left";
};

const Tags: FunctionComponent<TagsProps> = ({
  tags,
  currentTag,
  dummy,
  isBig = false,
  aligment = "right",
}) => {
  return (
    <TagsContainer aligment={aligment} id="tags">
      {!dummy && (
        <TagLink
          href="/#tags"
          tag="All"
          isSelected={currentTag === undefined}
          isBig={isBig}
        />
      )}

      {tags.map((tag) => (
        <TagLink
          isBig={isBig}
          href={`/tag/${getTag(tag)}#tags`}
          key={tag}
          tag={tag.trim()}
          isSelected={dummy ? false : currentTag === tag}
        />
      ))}
    </TagsContainer>
  );
};

export default Tags;
