import { media } from "components/legacy/styles/media";
import React, { FunctionComponent } from "react";
import styled from "styled-components";

type SafeAreaProps = {
  children?: React.ReactNode;
};

const SafeAreaContainer = styled.div`
  margin: 0px 64px;

  ${media.down("LAPTOP")`
    margin: 0 56px;
  `}

  ${media.down("TABLET")`
    margin: 0 44px;
  `}

  ${media.down("TABLET_SMALL")`
    margin: 0 40px;
  `}

  ${media.down("MOBILE_LARGE")`
    margin: 0 24px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin: 0 20px;
  `}
`;

// Component used to handle fixed left/right lines in Navbar.
const SafeArea: FunctionComponent<SafeAreaProps> = ({ children }) => {
  return <SafeAreaContainer>{children}</SafeAreaContainer>;
};

export default SafeArea;
