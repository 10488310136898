import { media } from "components/legacy/styles/media";
import React, { FunctionComponent } from "react";
import styled from "styled-components";

export const NavbarAreaContainer = styled.div`
  padding: 0 200px;

  ${media.down("LAPTOP")`
    padding: 0 144px;
  `}
  ${media.down("TABLET")`
    padding: 0 96px;
  `}
  ${media.down("TABLET_SMALL")`
    padding: 0 80px;
  `}
  ${media.down("MOBILE_LARGE")`
    padding: 0 48px;
  `}
  ${media.down("MOBILE_SMALL")`
    padding: 0 40px;
  `}
`;

const NavbarArea: FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return <NavbarAreaContainer>{children}</NavbarAreaContainer>;
};

export default NavbarArea;
