import { ImageBlur } from "components/ImagedBackground/ImagedBackground.components";
import React, { FunctionComponent } from "react";

export type ImageSide = "left" | "right";

type ImagePosition = {
  side: ImageSide;
};

type ImageProps = {
  top: number;
  side: ImageSide;
};

const BackgroundImage: FunctionComponent<ImageProps> = ({ top, side }) => {
  return (
    <>
      <ImageBlur top={top} side={side} />
    </>
  );
};

export default BackgroundImage;
