import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import { TagLStyle, TagMStyle, TagSStyle } from "styles/texts";
import { down } from "utils/media";

export const TagsContainer = styled.div<{
  aligment: "center" | "right" | "left";
}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > * {
    ${(props) =>
      props.aligment === "left"
        ? css`
            margin-right: 8px;
          `
        : css`
            margin-left: 8px;
          `}
  }

  > * {
    margin-bottom: 8px;
  }
  margin-bottom: -8px;

  ${(props) =>
    props.aligment === "right" &&
    css`
      justify-content: flex-end;
    `}

  ${(props) =>
    props.aligment === "center" &&
    css`
      justify-content: center;
    `}

${(props) =>
    props.aligment === "left" &&
    css`
      justify-content: flex-start;
    `}

  ${down("md")} {
    ${(props) =>
      props.aligment === "right" &&
      css`
        justify-content: flex-start;
        > * {
          margin-left: 0px;
          margin-right: 8px;
        }
      `}

    ${(props) =>
      props.aligment === "center" &&
      css`
        justify-content: center;
      `}
  }
`;

export const TagElement = styled.a<{ isSelected: boolean; isBig: boolean }>`
  ${(props) => (props.isBig ? TagLStyle() : TagSStyle())}
  border-radius: 100px;
  text-align: center;
  text-transform: ${(props) => (!props.isBig ? "none" : "uppercase")};
  display: block;
  padding: ${(props) => (props.isBig ? "14px 24px" : "8px 16px")};
  text-decoration: none;
  color: ${colors.text_main};
  border: 1px solid ${colors.warm_lines};

  ${down("lg")} {
    padding: 8px 16px;
    ${(props) => props.isBig && TagMStyle()}
  }

  ${(props) =>
    props.isSelected &&
    css`
      border: 1px solid ${colors.red._500};
      background-color: ${colors.red._500};
      color: ${colors.white};
    `};

  :focus-visible {
    outline: none;
    border: 1px solid ${colors.red._500};
    background-color: transparent;
    color: ${colors.text_main};
  }

  :hover {
    border: 1px solid ${colors.red._500};
    background-color: ${colors.red._80};
    color: ${colors.text_main};
  }
`;
