import { colors } from "components/legacy/styles/colors";
import styled from "styled-components";
import { down } from "utils/media";

export const BaseEffects = styled.svg`
  position: absolute;
  overflow: hidden;
  width: 0px;
  height: 0px;
`;

export const HiddenSvg = styled.svg`
  position: absolute;
  overflow: hidden;
  width: 0px;
  height: 0px;
`;

export const ShakeBoxInner = styled.div`
  display: block;
  position: relative;
  outline: 120px solid transparent;
  z-index: 2;
  height: 100%;
`;

export const ShakeBoxDeco = styled.div`
  outline: 118px solid transparent;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  ${down("md")} {
    display: none;
  }
`;

export const ShakeBoxContainer = styled.div`
  position: relative;
  outline: none;
  border-top: 1px solid rgba(164, 152, 152, 0.32);
  border-bottom: 1px solid rgba(164, 152, 152, 0.32);
  -webkit-tap-highlight-color: transparent;
  height: 100%;

  @media (pointer: fine) {
    &:hover {
      ${ShakeBoxDeco} {
        opacity: 1;
      }
      border-color: transparent;
      ${down("md")} {
        border-color: rgba(164, 152, 152, 0.32);
      }
    }
  }

  ${ShakeBoxDeco} {
    height: calc(100% - 1px);
    background: none;
    border: 1px solid ${colors.red._500};
    border-right: 0;
    border-left: 0;
    z-index: 1;
  }
`;
