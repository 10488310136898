export const subscribeEmail = async (email: string) => {
  try {
    const response = await fetch("/api/subscribe", {
      body: email,
      method: "POST",
      headers: { "Content-Type": "text/plain" },
    });

    if (!response.ok) {
      const body = await response.json();
      const error: string | undefined = body as string;
      throw Error(error || "Unexpected error.");
    }
  } catch (error) {
    throw error;
  }
};
