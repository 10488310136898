import { css } from "styled-components";
import { colors } from "styles/colors";

export const DefaultFocus = () => css`
  :focus-visible {
    outline-color: ${colors.red._500};
    outline-style: solid;
    outline-width: 2px;
  }
`;
