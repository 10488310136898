import { css } from "styled-components";

export const HorizontalGap = (px: number) => css`
  > * {
    margin-right: ${px}px;
    :last-child {
      margin-right: 0px;
    }
  }
`;

export const VerticalGap = (px: number) => css`
  > * + * {
    margin-bottom: ${px}px;
    :last-child {
      margin-bottom: 0px;
    }
  }
`;
