import { useMedia } from "use-media";

const breakpoints = {
  sm: 375,
  md: 700,
  lg: 900,
  xl: 1200,
  xl2: 1440,
} as const;

type BreakpointsNames = keyof typeof breakpoints;

export const downPx = (px: number) => `@media(max-width: ${px}px)`;

export const down = (name: BreakpointsNames) =>
  `@media(max-width: ${breakpoints[name]}px)`;

export const up = (name: BreakpointsNames) =>
  `@media(min-width: ${breakpoints[name]}px)`;

export const useIsMediaDown = (
  name: BreakpointsNames,
  defaultState?: boolean,
) => useMedia({ maxWidth: breakpoints[name] }, defaultState);

// To get information about immediately. useIsMediaDown initially always set value to false.
export const isMediaDown = (name: BreakpointsNames) =>
  window.innerWidth < breakpoints[name];
