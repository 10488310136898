import styled, { keyframes } from "styled-components";
import { colors } from "styles/colors";
import IconArrow from "./icon_arrow.webp";

const ArrowAnimation = keyframes`
  from { right: -22px; }
  to { right: -30px; }
`;

export const ReadMoreSpanContainer = styled.span`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  width: fit-content;
  color: ${colors.text_second};
  text-decoration: underline;
  text-decoration-line: underline;
  color: ${colors.red._500};
  position: relative;

  :hover {
    text-decoration: none;

    ::after {
      animation-duration: 0.75s;
      animation-name: ${ArrowAnimation};
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: linear;
    }
  }

  ::after {
    content: "";
    transition: transform 200ms ease-in-out;
    position: absolute;
    right: -22px;
    top: 2px;
    bottom: 0px;
    margin: auto 0px;
    width: 14px;
    height: 10px;
    background-size: 100%;
    background-image: url(${IconArrow.src});
    background-repeat: no-repeat;
  }
`;
