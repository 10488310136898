import React, { FunctionComponent } from "react";
import Link, { LinkProps } from "next/link";
import { TagElement } from "components/Tags/Tags.components";

type TagLinkProps = {
  tag: string;
  isSelected: boolean;
  isBig: boolean;
} & LinkProps;

const TagLink: FunctionComponent<TagLinkProps> = ({
  href,
  tag,
  isSelected,
  isBig,
}) => {
  return (
    <Link href={href} scroll={false} passHref>
      <TagElement isSelected={isSelected} isBig={isBig}>
        {tag}
      </TagElement>
    </Link>
  );
};

export default TagLink;
