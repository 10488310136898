import { useEffect, useState } from "react";

export const useSize = () => {
  const [size, setSize] = useState([0, 0]); // default width, detect on server.
  const handleResize = () => setSize([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);
  return size;
};
