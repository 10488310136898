import { ImagedBackgroundContainer } from "components/ImagedBackground/ImagedBackground.components";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useSize } from "utils/useSize";
import BackgroundImage from "components/ImagedBackground/BackgroundImage";

type ImagedBackgroundProps = {
  children?: React.ReactElement;
};

const ImagedBackground: FunctionComponent<ImagedBackgroundProps> = ({
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [images, setImages] = useState<JSX.Element[]>([]);
  const [width, height] = useSize();

  // Fire this effect on every window size change.
  useEffect(() => {
    const containerHeight = containerRef.current?.clientHeight ?? 0; // Height of images container.
    let imgIndex = 0; // Index of new image to display.
    let top = 0; // Current top position of new image.
    let isRight = true;
    const newImages: JSX.Element[] = []; // Array to store all image components.
    const gap = 500; // Vertical gap between images.

    // Add images only if there is enough space to display whole image.
    while (top < containerHeight) {
      const newTop = top + height + gap;
      // If there is enough space then add image to array to display it.
      if (newTop < containerHeight) {
        newImages.push(
          <BackgroundImage
            key={top}
            side={isRight ? "right" : "left"}
            top={top}
          />,
        );
      }

      isRight = !isRight;
      top = newTop;
      imgIndex += 1;
    }

    setImages(newImages);
  }, [height, width]);

  return (
    <ImagedBackgroundContainer ref={containerRef}>
      {children}
      {images}
    </ImagedBackgroundContainer>
  );
};

export default ImagedBackground;
