import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
} from "react";
import gsap from "gsap";
import {
  ShakeBoxDeco,
  ShakeBoxInner,
  HiddenSvg,
  ShakeBoxContainer,
} from "./ShakeBox.components";

const turbulenceValue = {
  turbulence: 0,
};

const turbulenceOptions = {
  duration: 0.4,
  startAt: { turbulence: 0.09 },
  turbulence: 0,
};

const ShakeBox: FunctionComponent<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const filterUrl = useId();
  const lineRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const turbulenceRef = useRef<SVGFETurbulenceElement>(null);
  const filterRef = useRef<SVGFilterElement>(null);
  const tlRef = useRef<gsap.core.Timeline>();

  const setLineFilter = useCallback((filter: string) => {
    if (lineRef.current) lineRef.current.style.filter = filter;
  }, []);

  const setFrequencyAttr = useCallback(() => {
    turbulenceRef.current?.setAttribute(
      "baseFrequency",
      turbulenceValue.turbulence.toString(),
    );
  }, []);

  const gsapTimeline = useMemo(
    () => ({
      paused: true,
      onStart: () => setLineFilter(`url(#${filterUrl})`),
      onComplete: () => setLineFilter("none"),
      onUpdate: () => setFrequencyAttr(),
    }),
    [filterUrl, setLineFilter, setFrequencyAttr],
  );

  useEffect(() => {
    tlRef.current = gsap
      .timeline(gsapTimeline)
      .to(turbulenceValue, turbulenceOptions);
  }, [gsapTimeline]);

  return (
    <ShakeBoxContainer
      onMouseEnter={() => tlRef.current?.restart()}
      onMouseLeave={() => tlRef.current?.progress(1).kill()}
    >
      <HiddenSvg>
        <defs>
          <filter ref={filterRef} id={filterUrl}>
            <feTurbulence
              ref={turbulenceRef}
              type="fractalNoise"
              baseFrequency="0"
              numOctaves="1"
              result="warp"
            />
            <feOffset dx="-90" result="warpOffset" />
            <feDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              scale="30"
              in="SourceGraphic"
              in2="warpOffset"
            />
          </filter>
        </defs>
      </HiddenSvg>
      <ShakeBoxInner ref={textRef} className="menu__link-inner">
        {children}
      </ShakeBoxInner>
      <ShakeBoxDeco ref={lineRef} className="menu__link-deco" />
    </ShakeBoxContainer>
  );
};
export default ShakeBox;
