import { ImageSide } from "components/ImagedBackground/BackgroundImage";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import { down } from "utils/media";

export const ImagedBackgroundContainer = styled.div`
  position: relative;
`;

export const ImageBlur = styled.div<{ side: ImageSide; top: number }>`
  z-index: 0;
  filter: blur(100px);
  opacity: 0.2;
  background-color: ${colors.red._500};
  width: 100px;
  height: 300px;
  position: absolute;
  left: ${({ side }) => (side === "left" ? "0px" : "unset")};
  right: ${({ side }) => (side === "right" ? "0px" : "unset")};
  top: ${({ top }) => top + 64}px;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  ${(props) =>
    props.side === "left" &&
    css`
      border-top-right-radius: 150px;
      border-bottom-right-radius: 150px;
    `}

  ${(props) =>
    props.side === "right" &&
    css`
      border-top-left-radius: 110px;
      border-bottom-left-radius: 110px;
    `}

  ${down("lg")} {
    display: none;
  }
`;
