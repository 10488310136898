import { motion } from "framer-motion";
import styled from "styled-components";
import { colors } from "styles/colors";
import {
  ArticleH4Style,
  ArticleH5Style,
  ArticleTitleStyle,
  ButtonStyle,
  LeadMStyle,
} from "styles/texts";
import { down } from "utils/media";

export const NewsletterBoxContainer = styled.div`
  overflow: hidden;
  width: 100%;
  border-radius: 32px;
  background-color: #181a1c; // This color is not added to styleguide.
`;

export const NewsletterAnimContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${down("lg")} {
    padding: 32px;
  }
`;

export const NewsletterBoxTitle = styled.span`
  ${ArticleTitleStyle};
  color: ${colors.white};
  text-align: center;

  ::selection {
    opacity: 1;
    color: ${colors.dark} !important;
    background-color: ${colors.red._500} !important;
  }
`;

export const NewsletterBoxDescription = styled.span`
  ${ArticleH4Style};
  margin-top: 8px;
  text-align: center;
  color: ${colors.text_second_white};

  ${down("md")} {
    margin-top: 4px;
  }

  ::selection {
    opacity: 1;
    color: ${colors.dark} !important;
    background-color: ${colors.red._500} !important;
  }
`;

export const NewsletterBoxForm = styled.form`
  position: relative;
  width: 100%;
  margin-top: 24px;
  padding: 0px;
  box-sizing: border-box;

  ${down("md")} {
    margin-top: 32px;
    max-width: 300px;
  }
`;

export const NewsletterBoxInputContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 512px;
  width: 100%;
  overflow: hidden;
`;

export const NewsletterBoxInput = styled.input`
  ${ArticleH5Style}
  color: ${colors.text_main};
  width: 100%;
  min-height: 56px;
  flex: 1;
  border-radius: 60px;
  box-sizing: border-box;
  border: none;
  background-color: ${colors.warm};
  outline: none;
  padding-left: 32px;
  padding-right: 150px;

  ${down("md")} {
    padding: 0px 16px;
    min-height: 48px;
  }

  :focus-visible {
    outline: none;
  }

  ::placeholder {
    color: ${colors.text_second};
  }
`;

export const NewsletterBoxButton = styled.button`
  ${ButtonStyle}
  line-height: 100%;
  color: ${colors.white};
  position: absolute;
  border-radius: 60px;
  padding: 16px 32px;
  box-sizing: border-box;
  margin: 0px;
  border: none;
  right: 0px;
  top: 0px;
  bottom: 0px;
  outline: none;
  background-color: ${colors.red._500};
  cursor: pointer;
  border: 1px solid ${colors.red._500};

  :hover,
  :focus-visible {
    border: 1px solid white;
  }

  ${down("md")} {
    margin-top: 24px;
    position: static;
    width: 100%;
  }
`;

export const NewsletterBoxError = styled.span`
  ${LeadMStyle};
  color: ${colors.red._500};
  margin-top: 4px;
`;
