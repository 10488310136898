import { css } from "styled-components";
import { down } from "utils/media";

export const ArticleTitleStyle = () => css`
  /* Article Title */
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 112%;
  letter-spacing: -0.02em;

  ${down("lg")} {
    font-size: 48px;
  }

  ${down("md")} {
    font-size: 44px;
  }
`;

export const LeadMStyle = () => css`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
`;

export const TagSStyle = () => css`
  /* Tags S */
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const TagMStyle = () => css`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const TagLStyle = () => css`
  /* Tags L */
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
`;

export const ArticleH1Style = () => css`
  /* Article Style/H1 - Big Title */
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 112%;
  letter-spacing: -0.02em;

  ${down("md")} {
    font-size: 40px;
    letter-spacing: -0.02em;
  }
`;

export const ArticleH2Style = () => css`
  /* Article Style/H2 - Medium Title */
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;

  ${down("md")} {
    font-size: 32px;
  }
`;

export const ArticleH3Style = () => css`
  /* Article Style/H3 - Small Title */
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;

  ${down("md")} {
    font-size: 20px;
  }
`;

export const ArticleH4Style = () => css`
  /* Article Style/H4 - Body Main */
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;

  ${down("md")} {
    font-size: 18px;
  }
`;

export const ArticleH5Style = () => css`
  /* Article Style/H5 - Body Small */
  font-family: "Barlow";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
`;

export const ArticleListTextStyle = () => css`
  /* Article Style/List Text */
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;

  ${down("md")} {
    font-size: 20px;
  }
`;

export const QuoteStyle = () => css`
  /* Article Style/Mobile/Quote Mobile */
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;

  ${down("md")} {
    font-size: 20px;
  }
`;

export const ButtonStyle = () => css`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
`;

export const ListTitleLStyle = () => css`
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 112%;
  letter-spacing: -0.02em;
  margin: 0px;
  padding: 0px;
`;

export const BlogTitleLStyle = () => css`
  /* Blog Title L */
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 112%;
  letter-spacing: -0.02em;
`;

export const BlogTitleMStyle = () => css`
  /* Blog Title M */
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 112%;
  letter-spacing: -0.02em;
`;

export const BlogTitleSStyle = () => css`
  /* Blog Title S */
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 112%;
  letter-spacing: -0.02em;
`;
